<template>
  <a-modal
    :visible="visible"
    :title="title"
    :width="700"
    @cancel="handleCancel"
    @ok="submit"
    :confirmLoading="confirmLoading"
  >
    <a-form-model
      :model="form"
      ref="ruleForm"
      layout="horizontal"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item ref="plugin" prop="plugin" label="扫描插件">
        <a-select
          show-search
          mode="multiple"
          v-model="form.plugins"
          placeholder="请选择扫描插件"
          :filter-option="filterOption"
        >
          <a-select-option value="" disabled="">请选择</a-select-option>
          <a-select-option
            v-for="item in plugin_info"
            :key="item.id"
            :value="item.id"
            >{{ item.name }} [{{ item.id }}]</a-select-option
          >
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { pluginList } from "@/api/plugin";
import { addTask } from "@/api/task";
export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: {
        plugins: [],
      },
      plugin_info: [],
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      title: "漏洞扫描",
      data: [],
    };
  },
  methods: {
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          addTask({
            name: "域名扫描",
            type: 2,
            plugins: this.form.plugins,
            data: this.data,
          })
            .then((res) => {
              if (res.data.status === 1) {
                this.$message.success(res.data.msg);
                this.visible = false;
              } else {
                this.$message.error(res.data.msg);
              }
            })
            .catch((err) => {
              this.$message.error(err.toString());
            });
          this.confirmLoading = false;
        }
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleCancel() {
      this.visible = false;
    },
    init(data) {
      this.form.plugins = [];
      this.data = data;
      this.visible = true;
      pluginList({ page: 1, page_size: 500, type: 2 })
        .then((res) => {
          this.plugin_info = res.data.results;
        })
        .catch((err) => {
          this.$message.error(err.toString());
        });
    },
  },
};
</script>
