<template>
  <a-modal
    :visible="visible"
    :title="title"
    :width="700"
    @cancel="handleCancel"
    @ok="submit"
    :confirmLoading="confirmLoading"
  >
    <a-form-model
      :model="form"
      ref="ruleForm"
      layout="horizontal"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="域名" ref="domain" prop="domain">
        <a-input v-model="form.domain"></a-input>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { addSubDomain, scanSubDomain } from "@/api/information";
export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      type: 0,
      form: {
        domain: "",
      },
      rules: {
        domain: [{ required: true, message: "类型不能为空", trigger: "blur" }],
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      title: "",
    };
  },
  methods: {
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          if (this.type === 0) {
            addSubDomain(this.form)
              .then((res) => {
                if (res.data.status == 0) {
                  this.$message.error(res.data.msg);
                } else {
                  this.$message.success(res.data.msg);
                  this.$parent.$parent.fetch();
                  this.visible = false;
                }
              })
              .catch((err) => {
                this.$message.error(err.toString());
              });
          } else {
            scanSubDomain(this.form)
              .then((res) => {
                if (res.data.status == 0) {
                  this.$message.error(res.data.msg);
                } else {
                  this.$message.success(res.data.msg);
                  this.$parent.$parent.fetch();
                  this.visible = false;
                }
              })
              .catch((err) => {
                this.$message.error(err.toString());
              });
          }
          this.confirmLoading = false;
        }
      });
    },
    handleCancel() {
      this.visible = false;
    },
    init(type) {
      this.type = type;
      if (this.type === 0) {
        this.title = "子域名添加";
      } else if (this.type === 1) {
        this.title = "子域名扫描";
      }
      this.visible = true;
    },
  },
};
</script>
