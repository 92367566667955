<template>
  <a-card :bordered="false" class="content" style="width: 100%">
    <a-button type="primary" icon="plus" @click="add()">添加域名</a-button>
    <a-button type="primary" icon="plus" @click="vul_scan()" style="margin-left: 10px;">漏洞扫描</a-button>
    <a-button type="primary" icon="plus" @click="sub_domain_scan()" style="margin-left: 10px;">子域名扫描</a-button>
    <a-input-search
      placeholder="全局搜索"
      style="width: 300px; float: right"
      v-model="globalSearch"
      @search="onSearch"
    />
    <a-table
      ref="table"
      style="width: 100%; margin-top: 10px;"
      size="small"
      :loading="loading"
      :rowKey="(row) => row.id"
      :columns="columns"
      :data-source="data"
      showPagination="auto"
      :pagination="pagination"
      @change="handleTableChange"
      bordered
    >
      <span slot="is_cdn" slot-scope="text">
        {{ text ? '是' : '否' }}
      </span>
      <span slot="is_alive" slot-scope="text">
        {{ text ? '是' : '否' }}
      </span>
      <span slot="source" slot-scope="text">
        {{ text | domainSource }}
      </span>
      <span slot="ip_info" slot-scope="text">
        {{ get_first_ip(text) }}
      </span>
      <span slot="action" slot-scope="text">
        <a-button
          type="danger"
          size="small"
          @click="delete_item(text.id)"
        >删除</a-button>
      </span>
      <p slot="expandedRowRender" slot-scope="text" style="margin: 0">
        <!-- <pre>{{ JSON.stringify(text.whois_info, null, 4) }}</pre> -->
        <pre>{{ get_info(text.ip_info) }}</pre>
      </p>
    </a-table>
    <sub-domain-scan ref="subDomainScan" />
    <vul-scan ref="vulScan" />
  </a-card>
</template>

<script>
import SubDomainScan from "./modules/SubDomainScan";
import VulScan from "./modules/VulScan";
import { getSubDomain, deleteSubDomain } from "@/api/information";
import consts from "@/constants/information";
import { Modal } from "ant-design-vue";
export default {
  components: {
    SubDomainScan,
    VulScan,
  },
  data() {
    return {
      loading: false,
      globalSearch: "",
      data: [],
      columns: [
        {
          title: "域名",
          dataIndex: "name",
          ellipsis: true,
          scopedSlots: { customRender: "name" },
          width: 80
        },
        {
          title: "ip",
          dataIndex: "ip_info",
          ellipsis: true,
          scopedSlots: { customRender: "ip_info" },
          width: 80
        },
        {
          title: "是否存活",
          dataIndex: "is_alive",
          ellipsis: true,
          scopedSlots: { customRender: "is_alive" },
          width: 80,
          filters: [
            {
              text: "否",
              value: 0,
            },
            {
              text: "是",
              value: 1,
            },
          ],
          filterMultiple: false,
        },
        {
          title: "是否为CDN",
          dataIndex: "is_cdn",
          ellipsis: true,
          scopedSlots: { customRender: "is_cdn" },
          width: 60,
          filters: [
            {
              text: "否",
              value: 0,
            },
            {
              text: "是",
              value: 1,
            },
          ],
          filterMultiple: false,
        },
        {
          title: "网站标题",
          dataIndex: "title",
          ellipsis: true,
          scopedSlots: { customRender: "title" },
          width: 80,
        },
        {
          title: "网站指纹",
          dataIndex: "banner",
          ellipsis: true,
          scopedSlots: { customRender: "banner" },
          width: 80
        },
        {
          title: "网站状态",
          dataIndex: "status",
          ellipsis: true,
          scopedSlots: { customRender: "status" },
          width: 80
        },
        {
          title: "来源",
          dataIndex: "source",
          ellipsis: true,
          scopedSlots: { customRender: "source" },
          width: 80,
          filters: [
            {
              text: "暴力破解",
              value: 0,
            },
            {
              text: "域传送漏洞",
              value: 1,
            },
            {
              text: "ssl证书查询",
              value: 2,
            },
            {
              text: "手动添加",
              value: 3,
            }
          ],
          filterMultiple: false,
        },
        {
          title: "操作",
          width: 80,
          scopedSlots: { customRender: "action" },
        },
      ],
      pagination: {
        defaultPageSize: 10,
        showQuickJumper: true,
        showTotal: (total) => `共${total}条数据`,
        showSizeChanger: true,
        pageSizeOptions: ["10", "50", "100", "200"],
        current: 1,
        pageSize: 10,
        onShowSizeChange: (current, pageSize) => (this.pagination.pageSize = pageSize),
      },
    };
  },
  methods: {
    sub_domain_scan () {
      this.$refs["subDomainScan"].init(1);
    },
    vul_scan() {
      var data = "";
      for (var i = 0; i < this.data.length; i++) {
        data += this.data[i].name;
        if (i < this.data.length - 1) {
          data += "\n";
        }
      }
      this.$refs["vulScan"].init(data);
    },
    onSearch() {
      this.fetch();
    },
    add() {
      this.$refs["subDomainScan"].init(0);
    },
    delete_item(id) {
        Modal.confirm({
        title: "警告",
        content: "您确定要删除此域名吗?",
        onOk: () => {
          deleteSubDomain(id)
            .then((res) => {
              if (res.status === 204) {
                this.$message.success("删除成功!");
                this.fetch();
              } else {
                this.$message.error("删除失败!");
              }
            })
            .catch((err) => {
              this.$message.error(err.toString());
            });
        },
        onCancel() {},
      });
    },
    get_first_ip(ip_info) {
      return Object.keys(ip_info)[0] + (Object.keys(ip_info).length > 1 ? "..." : "")
    },
    get_info(ip_info) {
      let info_length = Object.keys(ip_info).length;
      let count = 0;
      var result = "";
      for (let key in ip_info) {
        result += key + "\t";
        result += "cidr:" + ip_info[key][0] + "\t";
        result += "asn:" + ip_info[key][1] + "\t";
        result += "org:" + ip_info[key][2] + "\t";
        result += "addr:" + ip_info[key][3] + "\t";
        result += "isp:" + ip_info[key][4] + "\t";
        count += 1;
        console.log(count);
        if (count < info_length) {
          result += "\n";
        }
      }
      return result
    },
    fetch (query = {}) {
      this.loading = true;
      if (this.globalSearch) {
        query["search"] = this.globalSearch;
      }
      query["page_size"] = this.pagination.pageSize;
      query["page"] = this.pagination.current;
      getSubDomain(query)
        .then((res) => {
          const pagination = { ...this.pagination };
          pagination.total = parseInt(res.data.count);
          this.loading = false;
          this.data = res.data.results;
          this.pagination = pagination;
        })
        .catch((err) => {
          this.$message.error(err);
          this.loading = false;
        });
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      // 过滤
      let filterDic = {};
      for (var i in filters) {
        filterDic[i] = filters[i][0];
      }
      // 排序
      let orderingDic = {};
      if (sorter.order) {
        orderingDic["ordering"] =
          `${sorter.order === "ascend" ? "" : "-"}` + sorter.field;
      }
      this.fetch({...filterDic, ...orderingDic});
    },
  },
  filters: {
    domainSource(type) {
      return consts.domainSourceList[type];
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>


